import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody, } from '../../components/layouts'


import {
	Button,
	Image,
} from '../../components/ui'

import {
	linkBank, resetBankState, setMonthlyMin, setDonationType
} from '../../factory'

import Layout from '../../components/layout'

import { navigate } from '@reach/router'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			has_chargable_accounts: false,
			showError: false,
			monthly_min_donation_limit: 10
		}

		this.onChangeDonorType = this.onChangeDonorType.bind(this)
	}

	componentDidMount() {
		this.onChangeDonorType()
	}

	onChangeDonorType() {
		const {accountList} = this.props
		const hasTrackable = accountList.filter((acc) => acc.is_tracked_for_roundups).length > 0

		let donor_type = 0
		let daily_donation_sum = 0
		if (hasTrackable) {
			donor_type = 1
		} else {
			donor_type = 3
			daily_donation_sum = 0.5
		}

		const params = {
			donor_type,
			daily_donation_sum,
			register: true
		}

		this.props.setDonationType(params, this.props.token)
	}

	render() {
		return (
			<Layout>
				<Container>
					<PhoneBody title={"You did it!"}>
						<Flex column style={{alignItems: 'center'}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding4.png"}
								width={'125px'}
								marginTop={'25px'}
								height={'auto'}
								marginBottom={"0px"}
							/>
							<Heading as="h1" fontSize={'22px'}>Watch your cause Flourish</Heading>
							<Paragraph style={{marginTop: 10, textAlign: 'center', width: "80%"}}>You have selected your cause, linked your cards, and picked your donation type! FYI, it may take up to 3 days to populate your transactions for round ups due to bank security protocols.</Paragraph>
							<Button onClick={() => navigate('/admin/')}
								style={{marginTop: 20, maxWidth: 300}}>View My Impact</Button>
						</Flex>
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	bank_pending: PropTypes.bool,
	error: PropTypes.string,
	resetBankState: PropTypes.string,
	setMonthlyMin: PropTypes.string,
	accountList: PropTypes.array,
	setDonationType: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		bank_pending: state.bankState.bank_pending,
		error: state.bankState.error,
		accountList: state.userState.accountList,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkBank: (plaid_token, token, onSuccess, onErrorCallback) => dispatch(linkBank(plaid_token, token, onSuccess, onErrorCallback)),
		resetBankState: () => dispatch(resetBankState()),
		setMonthlyMin: (min, token, callback) => dispatch(setMonthlyMin(min, token,  callback)),
		setDonationType: (params, token) => dispatch(setDonationType(params, token)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
